// HighlightsList.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { highlightService } from '../services/highlightService';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const HighlightsList = () => {
  const [highlights, setHighlights] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchHighlights();
  }, []);

  const fetchHighlights = async () => {
    try {
      const data = await highlightService.getAllHighlights();
      setHighlights(data.feedItems);
    } catch (err) {
      setError('Failed to fetch highlights. Please try again.');
      console.error('Fetch highlights error:', err);
    }
  };

  const handleDelete = async (highlightId) => {
    if (window.confirm('Are you sure you want to delete this highlight?')) {
      try {
        const id = highlightId.includes('#') ? highlightId.split('#')[1] : highlightId;
        await highlightService.deleteHighlight(id);
        setHighlights(highlights.filter((highlight) => highlight.highlightId !== highlightId));
      } catch (err) {
        setError('Failed to delete highlight. Please try again.');
        console.error('Delete highlight error:', err);
      }
    }
  };

  const handleEdit = (highlightId) => {
    const id = highlightId.includes('#') ? highlightId.split('#')[1] : highlightId;
    navigate(`/edit-highlight/${id}`);
  };

  if (highlights.length === 0 && !error) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Highlights
        </Typography>
        {error && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/create-highlight"
          >
            Create New Highlight
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table aria-label="highlights table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Highlight ID</strong></TableCell>
                <TableCell><strong>Content Type</strong></TableCell>
                {/* <TableCell><strong>Target Audience</strong></TableCell>
                <TableCell><strong>Visibility Level</strong></TableCell> */}
                <TableCell><strong>Like Count</strong></TableCell>
                <TableCell><strong>PK</strong></TableCell>
                <TableCell><strong>SK</strong></TableCell>
                <TableCell align="right"><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {highlights.map((highlight) => (
                <TableRow key={highlight.highlightId}>
                  <TableCell>{highlight.highlightId}</TableCell>
                  <TableCell>{highlight.contentType}</TableCell>
                  {/* <TableCell>{highlight.targetAudience}</TableCell>
                  <TableCell>{highlight.visibilityLevel}</TableCell> */}
                  <TableCell>{highlight.likeCount}</TableCell>
                  <TableCell>{highlight.PK || highlight.pk}</TableCell>
                  <TableCell>{highlight.SK || highlight.sk}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleEdit(highlight.highlightId)}
                      color="primary"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(highlight.highlightId)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default HighlightsList;
