import React from 'react';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import FormField from './../FormField';

const CallDetailsTab = ({ formData, handleChange, isEditable }) => {
  // Debug logging
  console.log('CallDetailsTab - formData:', formData);
  console.log('CallDetailsTab - isEditable:', isEditable);

  const handleFieldChange = (event) => {
    // Get field name and value from the event
    const { name, value, type, files } = event.target;
    
    // For file inputs, pass the file object
    const fieldValue = type === 'file' ? files[0] : value;
    
    // Call parent's handleChange with field name and value
    handleChange(name, fieldValue);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Call Information
      </Typography>

      <Grid container spacing={3}>
        {/* Call Date */}
        <Grid item xs={12} md={4}>
          <FormField
            name="call_date"
            label="Date of Call"
            type="date"
            value={formData.call_date}
            handleChange={handleFieldChange}
            isEditable={isEditable}
          />
        </Grid>

        {/* Call Time */}
        <Grid item xs={12} md={4}>
          <FormField
            name="call_time"
            label="Time of Call"
            type="text"
            value={formData.call_time}
            handleChange={handleFieldChange}
            isEditable={isEditable}
          />
        </Grid>

        {/* Call Duration */}
        <Grid item xs={12} md={4}>
          <FormField
            name="call_duration"
            label="Call Duration (minutes)"
            type="number"
            value={formData.call_duration}
            handleChange={handleFieldChange}
            isEditable={isEditable}
          />
        </Grid>

        {/* Voice Note Upload */}
        <Grid item xs={12}>
          <FormField
            name="voice_note"
            label="Upload Voice Note"
            type="file"
            handleChange={handleFieldChange}
            isEditable={isEditable}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallDetailsTab;