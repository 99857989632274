import React from 'react';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import FormField from './../FormField';

const PreJoiningPhaseTab = ({ formData, handleChange, isEditable }) => {
  const handleFieldChange = (event) => {
    const { name, value, type, files } = event.target;
    const fieldValue = type === 'file' ? files[0] : value;
    handleChange(name, fieldValue);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Pre-Joining Phase Information
      </Typography>

      <Grid container spacing={3}>
        {/* Guidance Required */}
        <Grid item xs={12} md={6}>
          <FormField
            name="guidance_required"
            label="Guidance Required"
            type="select"
            value={formData.guidance_required}
            handleChange={handleFieldChange}
            options={['Yes', 'No']}
            isEditable={isEditable}
          />
        </Grid>

        {/* Guidance Type */}
        {formData.guidance_required === 'Yes' && (
          <Grid item xs={12} md={6}>
            <FormField
              name="guidance_type"
              label="Type of Guidance"
              type="select"
              value={formData.guidance_type}
              handleChange={handleFieldChange}
              options={[
                'Course Selection',
                'College Selection',
                'Scholarship Related',
                'Financial Assistance',
                'Travel Assistance',
                'Parental Counselling',
                'PWD Technological Assistance'
              ]}
              isEditable={isEditable}
            />
          </Grid>
        )}

        {/* Guidance Details */}
        {formData.guidance_required === 'Yes' && (
          <Grid item xs={12}>
            <FormField
              name="guidance_details"
              label="Guidance Details"
              type="text"
              value={formData.guidance_details}
              handleChange={handleFieldChange}
              isEditable={isEditable}
            />
          </Grid>
        )}

        {/* Guidance Status */}
        <Grid item xs={12} md={6}>
          <FormField
            name="guidance_status"
            label="Guidance Status"
            type="select"
            value={formData.guidance_status}
            handleChange={handleFieldChange}
            options={['Pending', 'In Progress', 'Completed']}
            isEditable={isEditable}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PreJoiningPhaseTab;