// QuizBlock.js
import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";

const QuizBlock = ({ block, updateBlock }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateBlock({
      ...block,
      quizContent: {
        ...block.quizContent,
        [name]: value,
      },
    });
  };

  const addQuestion = () => {
    const newQuestion = {
      id: uuidv4(),
      text: "",
      options: [],
      correctAnswer: "",
    };
    updateBlock({
      ...block,
      quizContent: {
        ...block.quizContent,
        questions: [...(block.quizContent?.questions || []), newQuestion],
      },
    });
  };

  const updateQuestion = (index, updatedQuestion) => {
    const updatedQuestions = [...(block.quizContent?.questions || [])];
    updatedQuestions[index] = updatedQuestion;
    updateBlock({
      ...block,
      quizContent: {
        ...block.quizContent,
        questions: updatedQuestions,
      },
    });
  };

  const addOption = (questionIndex) => {
    const updatedQuestions = [...(block.quizContent?.questions || [])];
    updatedQuestions[questionIndex].options.push({
      id: uuidv4(),
      text: "",
    });
    updateBlock({
      ...block,
      quizContent: {
        ...block.quizContent,
        questions: updatedQuestions,
      },
    });
  };

  return (
    <Box mt={2}>
      <TextField
        label="Quiz Title"
        name="title"
        value={block.quizContent?.title || ""}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Expiration Date"
        name="expiresAt"
        type="datetime-local"
        value={block.quizContent?.expiresAt || ""}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        fullWidth
        sx={{ mt: 2 }}
      />

      
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Quiz Settings
      </Typography>

      <FormControlLabel
        control={
          <Switch
            checked={block.quizContent?.allowMultipleResponses || false}
            onChange={(e) =>
              updateBlock({
                ...block,
                quizContent: {
                  ...block.quizContent,
                  allowMultipleResponses: e.target.checked,
                },
              })
            }
            name="allowMultipleResponses"
          />
        }
        label="Allow Multiple Attempts"
        sx={{ display: "block" }}
      />

      <FormControlLabel
        control={
          <Switch
            checked={block.quizContent?.requireAllQuestions || false}
            onChange={(e) =>
              updateBlock({
                ...block,
                quizContent: {
                  ...block.quizContent,
                  requireAllQuestions: e.target.checked,
                },
              })
            }
            name="requireAllQuestions"
          />
        }
        label="Require All Questions to be Answered"
        sx={{ display: "block", mt: 1 }}
      />

      {block.quizContent?.questions?.map((question, qIndex) => (
        <Paper key={question.id} sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">Question {qIndex + 1}</Typography>
          <TextField
            label="Question"
            value={question.text}
            onChange={(e) =>
              updateQuestion(qIndex, { ...question, text: e.target.value })
            }
            fullWidth
            sx={{ mt: 2 }}
          />
          {question.options.map((option, oIndex) => (
            <Box key={option.id} mt={2}>
              <TextField
                label={`Option ${oIndex + 1}`}
                value={option.text}
                onChange={(e) => {
                  const updatedOptions = [...question.options];
                  updatedOptions[oIndex] = { ...option, text: e.target.value };
                  updateQuestion(qIndex, {
                    ...question,
                    options: updatedOptions,
                  });
                }}
                fullWidth
              />
            </Box>
          ))}
          <Button
            variant="outlined"
            onClick={() => addOption(qIndex)}
            sx={{ mt: 2 }}
          >
            Add Option
          </Button>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Select Correct Answer</InputLabel>
            <Select
              value={question.correctAnswer}
              label="Select Correct Answer"
              onChange={(e) =>
                updateQuestion(qIndex, {
                  ...question,
                  correctAnswer: e.target.value,
                })
              }
            >
              {question.options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
      ))}
      <Button variant="outlined" onClick={addQuestion} sx={{ mt: 2 }}>
        Add Question
      </Button>
    </Box>
  );
};

export default QuizBlock;
