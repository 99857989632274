import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Box,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

const HostelFeesTab = ({ fees = [], isEditable, handleChange }) => {


  if (fees.length === 0) {
    fees = [
      {
        amount: '5000',
        academicYear: '2022',
        semester: 'Semester-1',
        paymentDate: '2022-01-01',
        paymentStatus: 'Pending',
        accommodationType: 'AC',
        receiptUrl: '',
      },
    ];
  }

  const handleFeeChange = (index, field, value) => {
    const updatedFees = [...fees];
    updatedFees[index][field] = value;
    handleChange({
      target: {
        name: 'hostelFees',
        value: updatedFees
      }
    });
  };

  const addNewFee = () => {
    handleChange({
      target: {
        name: 'hostelFees',
        value: [...fees, {
          amount: '',
          academicYear: '',
          semester: '',
          paymentDate: '',
          paymentStatus: '',
          accommodationType: '',
          receiptUrl: ''
        }]
      }
    });
  };

  const removeFee = (index) => {
    const updatedFees = fees.filter((_, i) => i !== index);
    handleChange({
      target: {
        name: 'hostelFees',
        value: updatedFees
      }
    });
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Hostel Fees</Typography>
          {isEditable && (
            <IconButton color="primary" onClick={addNewFee}>
              <AddIcon />
            </IconButton>
          )}
        </Box>

        {fees.map((fee, index) => (
          <Box key={index} sx={{ mb: 4, position: 'relative' }}>
            {isEditable && (
              <IconButton
                sx={{ position: 'absolute', right: -8, top: -8 }}
                onClick={() => removeFee(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Accommodation Type</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={fee.accommodationType}
                    label="Accommodation Type"
                    onChange={(e) => handleFeeChange(index, 'accommodationType', e.target.value)}
                  >
                    {['AC', 'Non-AC'].map(type => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Amount"
                  disabled={!isEditable}
                  value={fee.amount}
                  onChange={(e) => handleFeeChange(index, 'amount', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Academic Year"
                  disabled={!isEditable}
                  value={fee.academicYear}
                  onChange={(e) => handleFeeChange(index, 'academicYear', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Semester</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={fee.semester}
                    label="Semester"
                    onChange={(e) => handleFeeChange(index, 'semester', e.target.value)}
                  >
                    {[...Array(10)].map((_, i) => (
                      <MenuItem key={i} value={`Semester-${i + 1}`}>
                        Semester {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Payment Date"
                  disabled={!isEditable}
                  value={fee.paymentDate}
                  onChange={(e) => handleFeeChange(index, 'paymentDate', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Payment Status</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={fee.paymentStatus}
                    label="Payment Status"
                    onChange={(e) => handleFeeChange(index, 'paymentStatus', e.target.value)}
                  >
                    {['Pending', 'Partial', 'Completed'].map(status => (
                      <MenuItem key={status} value={status}>{status}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="file"
                  label="Receipt"
                  disabled={!isEditable}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleFeeChange(index, 'receiptUrl', e.target.files[0])}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default HostelFeesTab;