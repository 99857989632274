import React from 'react';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import FormField from './../FormField';

const DuringCollegeTab = ({ formData, handleChange, isEditable }) => {
  const handleFieldChange = (event) => {
    const { name, value, type, files } = event.target;
    const fieldValue = type === 'file' ? files[0] : value;
    handleChange(name, fieldValue);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        During College Information
      </Typography>

      <Grid container spacing={3}>
        {/* Support Required */}
        <Grid item xs={12} md={6}>
          <FormField
            name="college_support_required"
            label="Support Required"
            type="select"
            value={formData.college_support_required}
            handleChange={handleFieldChange}
            options={['Yes', 'No']}
            isEditable={isEditable}
          />
        </Grid>

        {/* Support Type */}
        {formData.college_support_required === 'Yes' && (
          <Grid item xs={12} md={6}>
            <FormField
              name="college_support_type"
              label="Type of Support"
              type="select"
              value={formData.college_support_type}
              handleChange={handleFieldChange}
              options={[
                'Financial Support',
                'Extra-curricular Activity Support',
                'Language Support',
                'Computer Skill Support',
                'Mentor Support',
                'Physical Health Support',
                'Mental Health Support'
              ]}
              isEditable={isEditable}
            />
          </Grid>
        )}

        {/* Support Details */}
        {formData.college_support_required === 'Yes' && (
          <Grid item xs={12}>
            <FormField
              name="college_support_details"
              label="Support Details"
              type="text"
              value={formData.college_support_details}
              handleChange={handleFieldChange}
              isEditable={isEditable}
            />
          </Grid>
        )}

        {/* Connectivity Questions */}
        <Grid item xs={12} md={6}>
          <FormField
            name="connected_with_seniors"
            label="Connected with Seniors"
            type="select"
            value={formData.connected_with_seniors}
            handleChange={handleFieldChange}
            options={['Yes', 'No']}
            isEditable={isEditable}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormField
            name="connected_with_classmates"
            label="Connected with Classmates"
            type="select"
            value={formData.connected_with_classmates}
            handleChange={handleFieldChange}
            options={['Yes', 'No']}
            isEditable={isEditable}
          />
        </Grid>

        {/* Academic Integration */}
        <Grid item xs={12} md={6}>
          <FormField
            name="regular_professor_interaction"
            label="Regular Professor Interaction"
            type="select"
            value={formData.regular_professor_interaction}
            handleChange={handleFieldChange}
            options={['Yes', 'No']}
            isEditable={isEditable}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormField
            name="participating_field_visits"
            label="Participating in Field Visits"
            type="select"
            value={formData.participating_field_visits}
            handleChange={handleFieldChange}
            options={['Yes', 'No']}
            isEditable={isEditable}
          />
        </Grid>

        {/* Field Visit Challenges */}
        {formData.participating_field_visits === 'Yes' && (
          <Grid item xs={12}>
            <FormField
              name="field_visit_challenges"
              label="Field Visit Challenges"
              type="text"
              value={formData.field_visit_challenges}
              handleChange={handleFieldChange}
              isEditable={isEditable}
            />
          </Grid>
        )}

        {/* Books Read */}
        <Grid item xs={12} md={6}>
          <FormField
            name="books_read_last_month"
            label="Books Read Last Month"
            type="number"
            value={formData.books_read_last_month}
            handleChange={handleFieldChange}
            isEditable={isEditable}
          />
        </Grid>

        {/* Challenges */}
        <Grid item xs={12}>
          <FormField
            name="challenges_faced"
            label="Challenges Faced"
            type="text"
            value={formData.challenges_faced}
            handleChange={handleFieldChange}
            isEditable={isEditable}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DuringCollegeTab;