import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Paper, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import PersonalDetailsTab from './../components/userProfile/PersonalDetailsTab';
import EducationalDetailsTab from './../components/userProfile/EducationalDetailsTab';
import FinancialDetailsTab from './../components/userProfile/FinancialDetailsTab';
import { getStudentById, updateStudent } from '../services/studentService';

const UserProfile = ({ isEditable }) => {
  const [tabValue, setTabValue] = useState(0);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { emisNumber } = useParams();

  // Separate state for each tab
  const [personalDetails, setPersonalDetails] = useState({});
  const [educationalDetails, setEducationalDetails] = useState({});
  const [financialDetails, setFinancialDetails] = useState({});
  const [files, setFiles] = useState({});

  useEffect(() => {
    console.log('Fetching student data for EMIS:', emisNumber);
    const fetchStudentData = async () => {
      try {
        setLoading(true);
        const data = await getStudentById(emisNumber);
        console.log('Fetched data:', data);
        setProfile(data);
        setPersonalDetails(data || {});
        setEducationalDetails(data.educationalDetails || {});
        setFinancialDetails(data.financialDetails || {});
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch student data:', err);
        setError('Failed to fetch student data');
        setLoading(false);
        showSnackbar('Failed to fetch student data', 'error');
      }
    };

    if (emisNumber) {
      fetchStudentData();
    }
  }, [emisNumber]);

  const showSnackbar = (message, severity = 'success') => {
    console.log('Showing snackbar:', { message, severity });
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    console.log('Snackbar closed');
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event, newValue) => {
    console.log('Tab changed to:', newValue);
    setTabValue(newValue);
  };

  const handleFileChange = (name, file) => {
    console.log(`File change detected for ${name}:`, file);
    setFiles(prev => ({
      ...prev,
      [name]: file,
    }));
  };

  const handleSavePersonalDetails = async () => {
    console.log('Saving Personal Details:', personalDetails);
    await saveTabData('personalDetails', personalDetails);
  };

  const handleSaveEducationalDetails = async () => {
    console.log('Saving Educational Details:', educationalDetails);
    await saveTabData('educationalDetails', educationalDetails);
  };

  const handleSaveFinancialDetails = async () => {
    console.log('Saving Financial Details:', financialDetails);
    await saveTabData('financialDetails', financialDetails);
  };

  const saveTabData = async (tabName, tabData) => {
    try {
      console.log(`Saving data for ${tabName}:`, tabData);
      setLoading(true);
      const formData = new FormData();
      formData.append(tabName, JSON.stringify(tabData));

      // Include relevant files
      Object.entries(files).forEach(([key, file]) => {
        if (key.startsWith(tabName)) {
          console.log(`Adding file for ${key}:`, file);
          formData.append(key, file);
        }
      });

      await updateStudent(emisNumber, formData);
      console.log(`${tabName} saved successfully`);
      showSnackbar(`${tabName} updated successfully`);
      setLoading(false);
    } catch (err) {
      console.error(`Failed to save ${tabName}:`, err);
      setError(`Failed to update ${tabName}`);
      setLoading(false);
      showSnackbar(`Failed to update ${tabName}`, 'error');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!profile) return <Typography>No student data found</Typography>;

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {isEditable ? 'Edit Profile' : 'View Profile'}
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Personal Details" />
        <Tab label="Educational Details" />
        <Tab label="Financial Details" />
      </Tabs>

      <Paper sx={{ p: 3, mt: 2 }}>
        {tabValue === 0 && (
          <PersonalDetailsTab
            profile={personalDetails}
            isEditable={isEditable}
            onChange={setPersonalDetails}
            onSave={handleSavePersonalDetails}
            onFileChange={handleFileChange}
          />
        )}
        {tabValue === 1 && (
          <EducationalDetailsTab
            profile={educationalDetails}
            isEditable={isEditable}
            onChange={setEducationalDetails}
            onSave={handleSaveEducationalDetails}
            onFileChange={handleFileChange}
          />
        )}
        {tabValue === 2 && (
          <FinancialDetailsTab
            profile={financialDetails}
            isEditable={isEditable}
            handleChange={setFinancialDetails}
            onSave={handleSaveFinancialDetails}
            onFileChange={handleFileChange}
          />
        )}
      </Paper>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserProfile;
