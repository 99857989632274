import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Box,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

const EntranceFeesTab = ({ fees = [], isEditable, handleChange }) => {

  if (fees.length === 0) {
    fees = [
      {
        examName: 'JEE Mains',
        examType: 'Engineering',
        dateOfApplication: '2022-01-01',
        amountPaid: '2000',
        feePaidBy: 'Personal',
        scholarshipAllocationStatus: false,
        receiptUrl: '',
      },
    ];
  }


  const handleFeeChange = (index, field, value) => {
    const updatedFees = [...fees];
    updatedFees[index][field] = value;
    handleChange({
      target: {
        name: 'entranceFees',
        value: updatedFees
      }
    });
  };

  const addNewFee = () => {
    handleChange({
      target: {
        name: 'entranceFees',
        value: [...fees, {
          examName: '',
          examType: '',
          dateOfApplication: '',
          amountPaid: '',
          feePaidBy: '',
          scholarshipAllocationStatus: false,
          receiptUrl: ''
        }]
      }
    });
  };

  const removeFee = (index) => {
    const updatedFees = fees.filter((_, i) => i !== index);
    handleChange({
      target: {
        name: 'entranceFees',
        value: updatedFees
      }
    });
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Entrance Exam Fees</Typography>
          {isEditable && (
            <IconButton color="primary" onClick={addNewFee}>
              <AddIcon />
            </IconButton>
          )}
        </Box>

        {fees.map((fee, index) => (
          <Box key={index} sx={{ mb: 4, position: 'relative' }}>
            {isEditable && (
              <IconButton
                sx={{ position: 'absolute', right: -8, top: -8 }}
                onClick={() => removeFee(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Entrance Exam</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={fee.examName}
                    label="Entrance Exam"
                    onChange={(e) => handleFeeChange(index, 'examName', e.target.value)}
                  >
                    {[
                      'JEE Mains',
                      'NEET',
                      'NATA'
                    ].map(exam => (
                      <MenuItem key={exam} value={exam}>{exam}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Exam Type</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={fee.examType}
                    label="Exam Type"
                    onChange={(e) => handleFeeChange(index, 'examType', e.target.value)}
                  >
                    {[
                      'Engineering',
                      'Medical',
                      'Architecture',
                      'Other'
                    ].map(type => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Application Date"
                  disabled={!isEditable}
                  value={fee.dateOfApplication}
                  onChange={(e) => handleFeeChange(index, 'dateOfApplication', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Amount Paid"
                  disabled={!isEditable}
                  value={fee.amountPaid}
                  onChange={(e) => handleFeeChange(index, 'amountPaid', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Fee Paid By</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={fee.feePaidBy}
                    label="Fee Paid By"
                    onChange={(e) => handleFeeChange(index, 'feePaidBy', e.target.value)}
                  >
                    {[
                      'Government',
                      'Personal',
                      'Scholarship',
                      'Other'
                    ].map(payer => (
                      <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>7.5% Scholarship Status</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={fee.scholarshipAllocationStatus}
                    label="7.5% Scholarship Status"
                    onChange={(e) => handleFeeChange(index, 'scholarshipAllocationStatus', e.target.value)}
                  >
                    <MenuItem value={true}>Allocated</MenuItem>
                    <MenuItem value={false}>Not Allocated</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="file"
                  label="Receipt"
                  disabled={!isEditable}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleFeeChange(index, 'receiptUrl', e.target.files[0])}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default EntranceFeesTab;