import React, { useState } from 'react';
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControl,
  InputLabel,
} from '@mui/material';

const EducationalDetailsTab = ({ profile, isEditable, handleChange, handleSave }) => {
  // State for educational details
  const [academicProfiles, setAcademicProfiles] = useState([{
    class: profile?.class || '',
    board: profile?.board || '',
    state: profile?.state || '',
    district: profile?.district || '',
    school: profile?.school || '',
    mediumOfInstruction: profile?.medium_of_instruction || '',
    passedOutYear: profile?.passed_out_year || '',
    marksObtained: profile?.marks_obtained || '',
  }]);

  // State for entrance exam details
  const [entranceExams, setEntranceExams] = useState([{
    examName: '',
    status: '',
    marksObtained: '',
    resultStatus: '',
    collegesApplied: [],
    courseApplied: '',
    applicationStatus: '',
    admissionStatus: ''
  }]);

  const handleEducationChange = (index, field, value) => {
    const updatedRecords = [...academicProfiles];
    updatedRecords[index][field] = value;
    setAcademicProfiles(updatedRecords);
    
    handleChange({
      target: {
        name: 'academicProfiles',
        value: updatedRecords
      }
    });
  };

  const handleEntranceExamChange = (index, field, value) => {
    const updatedExams = [...entranceExams];
    updatedExams[index][field] = value;
    setEntranceExams(updatedExams);
    
    handleChange({
      target: {
        name: 'entranceExams',
        value: updatedExams
      }
    });
  };

  const handleSubmit = () => {
    const educationalData = {
      academicProfiles,
      entranceExams
    };
    handleSave(educationalData);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Educational Records Section */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Educational Records
          </Typography>
          
          {academicProfiles.map((record, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Class</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={record.class}
                    label="Class"
                    onChange={(e) => handleEducationChange(index, 'class', e.target.value)}
                  >
                    {['10', '11', '12'].map((cls) => (
                      <MenuItem key={cls} value={cls}>Class {cls}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Board</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={record.board}
                    label="Board"
                    onChange={(e) => handleEducationChange(index, 'board', e.target.value)}
                  >
                    {['State Board', 'CBSE', 'ICSE'].map((board) => (
                      <MenuItem key={board} value={board}>{board}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Medium of Instruction</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={record.mediumOfInstruction}
                    label="Medium of Instruction"
                    onChange={(e) => handleEducationChange(index, 'mediumOfInstruction', e.target.value)}
                  >
                    {['Tamil', 'English', 'Other Language'].map((medium) => (
                      <MenuItem key={medium} value={medium}>{medium}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Marks Obtained"
                  disabled={!isEditable}
                  value={record.marksObtained}
                  onChange={(e) => handleEducationChange(index, 'marksObtained', e.target.value)}
                />
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>

      {/* Entrance Exams Section */}
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Entrance Exams
          </Typography>
          
          {entranceExams.map((exam, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Entrance Exam</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={exam.examName}
                    label="Entrance Exam"
                    onChange={(e) => handleEntranceExamChange(index, 'examName', e.target.value)}
                  >
                    {['JEE Mains', 'NEET', 'NATA'].map((examName) => (
                      <MenuItem key={examName} value={examName}>{examName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    disabled={!isEditable}
                    value={exam.status}
                    label="Status"
                    onChange={(e) => handleEntranceExamChange(index, 'status', e.target.value)}
                  >
                    {[
                      'Applied - Waiting for the exam',
                      'Written - Waiting for results',
                      'Results released'
                    ].map((status) => (
                      <MenuItem key={status} value={status}>{status}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {exam.status === 'Results released' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Marks/Rank Obtained"
                      disabled={!isEditable}
                      value={exam.marksObtained}
                      onChange={(e) => handleEntranceExamChange(index, 'marksObtained', e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Result Status</InputLabel>
                      <Select
                        disabled={!isEditable}
                        value={exam.resultStatus}
                        label="Result Status"
                        onChange={(e) => handleEntranceExamChange(index, 'resultStatus', e.target.value)}
                      >
                        {['Qualified', 'Waitlisted', 'Not qualified'].map((status) => (
                          <MenuItem key={status} value={status}>{status}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        </CardContent>
      </Card>

      {isEditable && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSubmit}
          >
            Save Educational Details
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EducationalDetailsTab;