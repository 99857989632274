import React from 'react';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import FormField from './../FormField';

const PostCollegeTab = ({ formData, handleChange, isEditable }) => {
  const handleFieldChange = (event) => {
    const { name, value, type, files } = event.target;
    const fieldValue = type === 'file' ? files[0] : value;
    handleChange(name, fieldValue);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Post College Information
      </Typography>

      <Grid container spacing={3}>
        {/* Support Required After College */}
        <Grid item xs={12} md={6}>
          <FormField
            name="post_support_required"
            label="Support Required After College"
            type="select"
            value={formData.post_support_required}
            handleChange={handleFieldChange}
            options={['Yes', 'No']}
            isEditable={isEditable}
          />
        </Grid>

        {/* Support Type */}
        {formData.post_support_required === 'Yes' && (
          <Grid item xs={12} md={6}>
            <FormField
              name="post_support_type"
              label="Type of Support"
              type="select"
              value={formData.post_support_type}
              handleChange={handleFieldChange}
              options={[
                'Available Career Opportunities',
                'Campus Placements',
                'Project and Project Publications',
                'Skill Support for Employment',
                'Job Openings'
              ]}
              isEditable={isEditable}
            />
          </Grid>
        )}

        {/* Support Details */}
        {formData.post_support_required === 'Yes' && (
          <Grid item xs={12}>
            <FormField
              name="post_support_details"
              label="Support Details"
              type="text"
              value={formData.post_support_details}
              handleChange={handleFieldChange}
              isEditable={isEditable}
            />
          </Grid>
        )}

        {/* Support Status */}
        {formData.post_support_required === 'Yes' && (
          <Grid item xs={12} md={6}>
            <FormField
              name="post_support_status"
              label="Support Status"
              type="select"
              value={formData.post_support_status}
              handleChange={handleFieldChange}
              options={['Pending', 'Closed', 'Ongoing']}
              isEditable={isEditable}
            />
          </Grid>
        )}

        {/* Employment Status */}
        <Grid item xs={12} md={6}>
          <FormField
            name="employment_status"
            label="Employment Status"
            type="select"
            value={formData.employment_status}
            handleChange={handleFieldChange}
            options={[
              'Employed',
              'Waiting for Results',
              'Not Employed'
            ]}
            isEditable={isEditable}
          />
        </Grid>

        {/* Employment Details - Show only if employed */}
        {formData.employment_status === 'Employed' && (
          <>
            <Grid item xs={12} md={6}>
              <FormField
                name="company_name"
                label="Name of the Company/Organization/Institution"
                type="text"
                value={formData.company_name}
                handleChange={handleFieldChange}
                isEditable={isEditable}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormField
                name="company_location"
                label="Location of the Company/Organization/Institution"
                type="text"
                value={formData.company_location}
                handleChange={handleFieldChange}
                isEditable={isEditable}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormField
                name="annual_ctc"
                label="Annual CTC"
                type="number"
                value={formData.annual_ctc}
                handleChange={handleFieldChange}
                isEditable={isEditable}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormField
                name="employment_start_date"
                label="Employment Start Date"
                type="date"
                value={formData.employment_start_date}
                handleChange={handleFieldChange}
                isEditable={isEditable}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormField
                name="current_employment_status"
                label="Current Employment Status"
                type="select"
                value={formData.current_employment_status}
                handleChange={handleFieldChange}
                options={[
                  'Currently Working',
                  'Resigned',
                  'Contract Completed'
                ]}
                isEditable={isEditable}
              />
            </Grid>

            {formData.current_employment_status !== 'Currently Working' && (
              <>
                <Grid item xs={12} md={6}>
                  <FormField
                    name="exit_date"
                    label="Exit Date"
                    type="date"
                    value={formData.exit_date}
                    handleChange={handleFieldChange}
                    isEditable={isEditable}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormField
                    name="exit_reason"
                    label="Reason for Exit"
                    type="text"
                    value={formData.exit_reason}
                    handleChange={handleFieldChange}
                    isEditable={isEditable}
                  />
                </Grid>
              </>
            )}
          </>
        )}

        {/* Further Education Details */}
        <Grid item xs={12} md={6}>
          <FormField
            name="pursuing_further_education"
            label="Pursuing Further Education"
            type="select"
            value={formData.pursuing_further_education}
            handleChange={handleFieldChange}
            options={['Yes', 'No']}
            isEditable={isEditable}
          />
        </Grid>

        {formData.pursuing_further_education === 'Yes' && (
          <>
            <Grid item xs={12} md={6}>
              <FormField
                name="course_name"
                label="Course Name"
                type="text"
                value={formData.course_name}
                handleChange={handleFieldChange}
                isEditable={isEditable}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormField
                name="institution_name"
                label="Institution Name"
                type="text"
                value={formData.institution_name}
                handleChange={handleFieldChange}
                isEditable={isEditable}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormField
                name="course_duration"
                label="Course Duration (in years)"
                type="number"
                value={formData.course_duration}
                handleChange={handleFieldChange}
                isEditable={isEditable}
              />
            </Grid>
          </>
        )}

        {/* Additional Comments */}
        <Grid item xs={12}>
          <FormField
            name="additional_comments"
            label="Additional Comments"
            type="text"
            value={formData.additional_comments}
            handleChange={handleFieldChange}
            isEditable={isEditable}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PostCollegeTab;