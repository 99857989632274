import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Paper,
  Breadcrumbs,
  Link,
  Grid,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
// import FilterListIcon from '@mui/icons-material/FilterList';
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShareIcon from "@mui/icons-material/Share";
import { styled } from "@mui/material/styles";
import RankingService from "../services/rankingService";

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const columns = [
  { field: "id", headerName: "S.No.", width: 70 },
  { field: "first_name", headerName: "First Name", width: 150 },
  { field: "contact", headerName: "Contact", width: 150 },
  { field: "gender", headerName: "Gender", width: 100 },
  { field: "year", headerName: "Year", width: 80 },
  { field: "country", headerName: "Country", width: 100 },
  { field: "state", headerName: "State", width: 120 },
  { field: "city", headerName: "City", width: 120 },
  { field: "institute", headerName: "Institute name", width: 200 },
  { field: "stream", headerName: "Stream", width: 150 },
  { field: "course", headerName: "Course", width: 150 },
  { field: "status", headerName: "Current Status", width: 150 },
];

const SelectionRankingPage = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [filters, setFilters] = useState({
    country: "",
    state: "",
    city: "",
    stream: "",
  });

  const fetchRankings = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await RankingService.getRankings(page + 1, pageSize, {
        ...filters,
        search: searchText,
      });
      setRows(response.data || []);
      setTotalRows(response.pagination?.total || 0); 
    } catch (err) {
      setError("Failed to fetch rankings. Please try again later.");
      console.error("Error fetching rankings:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRankings();
  }, [page, pageSize, filters]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    // Debounce search to avoid too many API calls
    const timeoutId = setTimeout(() => {
      setPage(0); // Reset to first page when searching
      fetchRankings();
    }, 500);
    return () => clearTimeout(timeoutId);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleColumnVisibilityChange = (column) => {
    setVisibleColumns((prev) =>
      prev.find((col) => col.field === column.field)
        ? prev.filter((col) => col.field !== column.field)
        : [...prev, column]
    );
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPage(0); // Reset to first page when filters change
  };

  return (
    <Box sx={{ p: 3 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link color="inherit" href="/">
          Home
        </Link>
        <Link color="inherit" href="/user-management">
          User Management
        </Link>
        <Typography color="textPrimary">Ranking Data</Typography>
      </Breadcrumbs>

      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="h1" color="primary">
            Selection Ranking Data
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search for anything..."
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <IconButton size="small">
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            <StyledButton
              variant="outlined"
              color="primary"
              startIcon={<ShareIcon />}
            >
              Share the List
            </StyledButton>
            <StyledButton
              variant="outlined"
              color="primary"
              startIcon={<VisibilityIcon />}
              onClick={handleMenuClick}
            >
              Show / Hide Columns
            </StyledButton>
          </Box>

          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Country</InputLabel>
              <Select
                value={filters.country}
                label="Country"
                name="country"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="India">India</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>State</InputLabel>
              <Select
                value={filters.state}
                label="State"
                name="state"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Stream</InputLabel>
              <Select
                value={filters.stream}
                label="Stream"
                name="stream"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Electronic">Electronic</MenuItem>
                <MenuItem value="Literature">Literature</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {columns.map((column) => (
          <MenuItem
            key={column.field}
            onClick={() => handleColumnVisibilityChange(column)}
          >
            <Checkbox
              checked={
                visibleColumns.find((col) => col.field === column.field) !==
                undefined
              }
            />
            {column.headerName}
          </MenuItem>
        ))}
      </Menu>

      <Paper sx={{ height: 600, width: "100%" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography color="error">{error}</Typography>
          </Box>
        ) : (
          <DataGrid
            rows={rows}
            columns={visibleColumns}
            pagination
            page={page}
            pageSize={pageSize}
            rowCount={totalRows}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50]}
            paginationMode="server"
            filterMode="server"
            loading={loading}
            checkboxSelection
            disableSelectionOnClick
            getRowId={(row) => row.first_name + row.contact + Math.random()}
            sx={{
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#4A90E2', // Header background
      color: '#000000', // Header text
      fontSize: '16px',
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-row:nth-of-type(odd)': {
      backgroundColor: '#F9F9F9', // Lighter odd rows
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: '#ECECEC', // Slightly darker even rows
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#E6F7FF', // Distinct hover color
    },
    '& .MuiCheckbox-root': {
      color: '#4A90E2', // Checkbox color
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: '#D1E8FF', // Selected row background
      '&:hover': {
        backgroundColor: '#A3CFFF', // Hover on selected row
      },
    },
  }}
/>
        )}
      </Paper>
    </Box>
  );
};

export default SelectionRankingPage;
