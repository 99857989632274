import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import EntranceFeesTab from './fees/EntranceFeesTab';
import InstituteFeesTab from './fees/InstituteFeesTab';
import HostelFeesTab from './fees/HostelFeesTab';
import MessFeesTab from './fees/MessFeesTab';
import OtherFeesTab from './fees/OtherFeesTab';

const FinancialDetailsTab = ({ profile, isEditable, handleChange, handleSave }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSubmit = () => {
    const financialData = {
      // Combine all fee data here
      EntranceFees: profile.EntranceFees,
      InstituteFees: profile.InstituteFees,
      HostelFees: profile.HostelFees,
      MessFees: profile.MessFees,
      OtherFees: profile.OtherFees
    };
    handleSave(financialData);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{ mb: 2 }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Institute Fees" />
        <Tab label="Hostel Fees" />
        <Tab label="Mess Fees" />
        <Tab label="Entrance Fees" />
        <Tab label="Other Fees" />
      </Tabs>

      <Box sx={{ mt: 2 }}>
        {activeTab === 0 && (
          <InstituteFeesTab
            fees={profile.InstituteFees}
            isEditable={isEditable}
            handleChange={handleChange}
          />
        )}
        {activeTab === 1 && (
          <HostelFeesTab
            fees={profile.hostelFees}
            isEditable={isEditable}
            handleChange={handleChange}
          />
        )}
        {activeTab === 2 && (
          <MessFeesTab
            fees={profile.messFees}
            isEditable={isEditable}
            handleChange={handleChange}
          />
        )}
        {activeTab === 3 && (
          <EntranceFeesTab
            fees={profile.entranceFees}
            isEditable={isEditable}
            handleChange={handleChange}
          />
        )}
        {activeTab === 4 && (
          <OtherFeesTab
            fees={profile.otherFees}
            isEditable={isEditable}
            handleChange={handleChange}
          />
        )}
      </Box>

      {isEditable && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSubmit}
          >
            Save All Fee Details
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FinancialDetailsTab;