// services/rankingService.js
import {nodeApi} from './api';

export const RankingService = {
  getRankings: async (page = 1, limit = 10, filters = {}) => {
    try {
      const queryParams = new URLSearchParams({
        page,
        limit,
        ...filters
      });

      const response = await nodeApi.get(`/api/rankings/rankings?${queryParams}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching rankings:', error);
      throw error;
    }
  }
};

export default RankingService;