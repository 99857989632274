import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:9090', // -- java backend
  baseURL: 'https://tns.app.bhaninnovation.com/api', // Replace with your API base URL
});

const nodeApi = axios.create({
  // baseURL: 'http://localhost:3000', // -- node backend 
  baseURL: 'https://tns.app.bhaninnovation.com/node-api', // Replace with your API base URL
});


api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      console.log('403 error need to take further action');
      // Remove token from localStorage when a 403 error occurs
      localStorage.removeItem('token');

      // Optionally, you can navigate the user to the login page
      // if you're using React Router:
      // window.location.href = '/login';

      // Or if you have access to a navigate function:
      // navigate('/login');
    }
    return Promise.reject(error);
  }
);

export { api, nodeApi };