
// CcaaEditor.js
import React from 'react';
import CcaaInputs from './CcaaInputs';

const CcaaEditor = () => {
  return <CcaaInputs isEditable={true} />;
};

export default CcaaEditor;
