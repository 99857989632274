import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Breadcrumbs,
  Link,
  Button,
} from "@mui/material";
import CallDetailsTab from "./../components/ccaainputs/CallDetailsTab";
import PreJoiningPhaseTab from "./../components/ccaainputs/PreJoiningPhaseTab";
import DuringCollegeTab from "./../components/ccaainputs/DuringCollegeTab";
import PostCollegeTab from "./../components/ccaainputs/PostCollegeTab";

const CcaaInputs = ({ isEditable = true }) => {
  const [activeTab, setActiveTab] = useState(0);

  // Initialize form data with all fields for all tabs
  const [formData, setFormData] = useState({
    // Call Details
    call_date: "",
    call_time: "",
    call_duration: "",
    support_required: "",
    support_type: "",
    support_details: "",
    priority_level: "",
    follow_up_date: "",
    voice_note: null,

    // Pre-Joining Phase
    guidance_required: "",
    guidance_type: "",
    guidance_details: "",
    guidance_status: "",

    // During College
    college_support_required: "",
    college_support_type: "",
    college_support_details: "",
    connected_with_seniors: "",
    connected_with_classmates: "",
    assigned_academic_mentor: "",
    contact_with_juniors: "",
    contact_with_school_friends: "",
    books_read_last_month: "",
    social_media_groups_school: "",
    social_media_groups_college: "",
    regular_school_contact: "",
    regular_professor_interaction: "",
    participating_field_visits: "",
    field_visit_challenges: "",
    extracurricular_activities: "",
    challenges_faced: "",

    // Post College
    post_support_required: "",
    post_support_type: "",
    post_support_details: "",
    post_support_status: "",
    employment_status: "",
    company_name: "",
    company_location: "",
    annual_ctc: "",
    employment_start_date: "",
    current_employment_status: "",
    exit_date: "",
    exit_reason: "",
    pursuing_further_education: "",
    course_name: "",
    institution_name: "",
    course_duration: "",
    additional_comments: "",
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChange = (name, value) => {
    // console.log("Updating field:", name, "with value:", value);

    setFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
      };
    //   console.log("Updated form data:", newData);
      return newData;
    });
  };

  const handleSubmit = async () => {
    try {
      console.log("Submitting form data:", formData);
      // Add your API call here
      // await saveCcaaData(formData);
    } catch (error) {
      console.error("Error saving CCAA data:", error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link color="inherit" href="/">
          Home
        </Link>
        <Typography color="textPrimary">CCAA Inputs</Typography>
      </Breadcrumbs>

      <Typography variant="h4" gutterBottom>
        CCAA Inputs
      </Typography>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ mb: 2 }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Call Details" />
          <Tab label="Pre-Joining Phase" />
          <Tab label="During College" />
          <Tab label="Post College" />
        </Tabs>

        <Box sx={{ p: 2 }}>
          {activeTab === 0 && (
            <CallDetailsTab
              formData={formData}
              handleChange={handleChange}
              isEditable={isEditable}
            />
          )}
          {activeTab === 1 && (
            <PreJoiningPhaseTab
              formData={formData}
              handleChange={handleChange}
              isEditable={isEditable}
            />
          )}
          {activeTab === 2 && (
            <DuringCollegeTab
              formData={formData}
              handleChange={handleChange}
              isEditable={isEditable}
            />
          )}
          {activeTab === 3 && (
            <PostCollegeTab
              formData={formData}
              handleChange={handleChange}
              isEditable={isEditable}
            />
          )}

          {isEditable && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Save CCAA Details
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default CcaaInputs;
